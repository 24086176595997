// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconBlockquote,
    IconAdjustments,
    IconDoorExit,
    IconUsers,
    IconUser,
    IconArticle,
    IconSettings,
} from '@tabler/icons'

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconBlockquote,
    IconAdjustments,
    IconDoorExit,
    IconUsers,
    IconUser,
    IconArticle,
    IconSettings,
}

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: icons.IconBrandChrome,
            breadcrumbs: false,
        },
        {
            id: 'admins',
            title: <FormattedMessage id="Admins" />,
            type: 'item',
            url: '/admins',
            icon: icons.IconUser,
            breadcrumbs: false,
        },
        {
            id: 'clients',
            title: <FormattedMessage id="Clients" />,
            type: 'item',
            url: '/clients',
            icon: icons.IconUsers,
            breadcrumbs: false,
        },
        {
            id: 'survey-questions',
            title: <FormattedMessage id="survey-questions" />,
            type: 'item',
            url: '/survey-questions',
            icon: icons.IconHelp,
            breadcrumbs: false,
        },
        {
            id: 'coaching-survey',
            title: <FormattedMessage id="coaching-survey" />,
            type: 'item',
            url: '/coaching-survey',
            icon: icons.IconHelp,
            breadcrumbs: false,
        },
        {
            id: 'quotes',
            title: <FormattedMessage id="quotes" />,
            type: 'item',
            url: '/quotes',
            icon: icons.IconBlockquote,
            breadcrumbs: false,
        },
        {
            id: 'mantras',
            title: <FormattedMessage id="mantras" />,
            type: 'item',
            url: '/mantras',
            icon: icons.IconBlockquote,
            breadcrumbs: false,
        },
        {
            id: 'welcome',
            title: <FormattedMessage id="welcome" />,
            type: 'item',
            url: '/welcome',
            icon: icons.IconBlockquote,
            breadcrumbs: false,
        },
        {
            id: 'exit-survey',
            title: <FormattedMessage id="exit-survey" />,
            type: 'item',
            url: '/exit-survey',
            icon: icons.IconDoorExit,
            breadcrumbs: false,
        },
        {
            id: 'content-categories',
            title: <FormattedMessage id="content-categories" />,
            type: 'item',
            url: '/content-categories',
            icon: icons.IconAdjustments,
            breadcrumbs: false,
        },
        {
            id: 'throttle-settings',
            title: <FormattedMessage id="throttle-settings" />,
            type: 'item',
            url: '/throttle-settings',
            icon: icons.IconSettings,
            breadcrumbs: false,
        },
        {
            id: 'generate-article',
            title: <FormattedMessage id="generate-article" />,
            type: 'item',
            url: '/article',
            icon: icons.IconArticle,
            breadcrumbs: false,
        },
    ],
}

export default other
