// third-party
import { combineReducers } from 'redux'

// project imports
import snackbarReducer from './slices/snackbar'
import userReducer from './slices/user'
import questionsReducer from './slices/questions'
import menuReducer from './slices/menu'
import quotesReducer from './slices/quotes'
import preferenceReducer from './slices/preference'
import exitSurveyReducer from './slices/exitSurveyQuestions'
import clientsReducer from './slices/clients'
import adminsReducer from './slices/admins'
import CommunicationLogReducer from './slices/communicationLog'
import throttleReducer from './slices/throttleSettings'
import mantrasReducer from './slices/mantras'
import coachingQuestionReducer from './slices/coaching-questions'
import welcomeReducer from './slices/welcomeSlice'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    questions: questionsReducer,
    coachingQuestion: coachingQuestionReducer,
    exitSurvey: exitSurveyReducer,
    quotes: quotesReducer,
    mantras: mantrasReducer,
    users: userReducer,
    menu: menuReducer,
    preference: preferenceReducer,
    clients: clientsReducer,
    communicationLog: CommunicationLogReducer,
    admins: adminsReducer,
    throttle: throttleReducer,
    welcome: welcomeReducer,
})

export default reducer
