// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getWelcome = createAsyncThunk(
    'mantras/getWelcome',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/welcome')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const updateWelcome = createAsyncThunk(
    'admin/updateWelcome',
    async (welcome: any, { rejectWithValue }) => {
        try {
            const response = await axios.put(`admin/updateWelcome`, welcome)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Welcome Message updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getWelcome())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)

const apiResponse: any = {
    data: {},
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    welcome: apiResponse,
    updateWelcome: apiResponse,
}

const welcomeSlice = createSlice({
    name: 'welcomeSlice',
    initialState,
    reducers: {
        clearMantras: (state: any, action: PayloadAction<any>) => {
            state.mantras = apiResponse
        },
        setMantrasData: (state: any, action: PayloadAction<any>) => {
            state.mantras.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWelcome.pending, (state: any) => {
                state.welcome.isFetching = true
            })
            .addCase(
                getWelcome.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.welcome.data = payload
                    state.welcome.isFetching = false
                }
            )
            .addCase(
                getWelcome.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.welcome.isError = true
                    state.welcome.isFetching = false
                    state.welcome.errorMessage = payload
                }
            )

            .addCase(updateWelcome.pending, (state: any) => {
                state.updateWelcome.isFetching = true
            })
            .addCase(
                updateWelcome.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.updateWelcome.isFetching = false
                    state.updateWelcome.isSuccess = true
                }
            )
            .addCase(
                updateWelcome.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.updateWelcome.isError = true
                    state.updateWelcome.isFetching = false
                    state.updateWelcome.errorMessage = payload
                }
            )
    },
})

export const { clearMantras, setMantrasData } = welcomeSlice.actions
export const welcomeSelector = (state: RootState) => state.welcome
export default welcomeSlice.reducer
