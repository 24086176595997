import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'

// sample page routing
const UserSimpleCard = Loadable(lazy(() => import('views/pages/users')))
const UserDetail = Loadable(lazy(() => import('views/pages/usersDetails')))
const Questions = Loadable(lazy(() => import('views/pages/questions')))
const CoachingQuestions = Loadable(
    lazy(() => import('views/pages/coaching-questions'))
)
const Quotes = Loadable(lazy(() => import('views/pages/quotes')))
const Mantras = Loadable(lazy(() => import('views/pages/mantras')))
const AppWelcome = Loadable(lazy(() => import('views/pages/welcome')))
const ExitSurvey = Loadable(lazy(() => import('views/pages/exitSurvey')))
const Preferences = Loadable(lazy(() => import('views/pages/preferences')))
const CommunicationLog = Loadable(
    lazy(() => import('views/pages/cmmunicationLog'))
)
const Clients = Loadable(lazy(() => import('views/pages/clients/Clients')))
const Admins = Loadable(lazy(() => import('views/pages/admins')))
const QuizDetails = Loadable(lazy(() => import('views/pages/quizDetails')))
const GenerateArticle = Loadable(lazy(() => import('views/pages/article')))
const MessageDetails = Loadable(
    lazy(() => import('views/pages/messageDetails'))
)
const ClientsUser = Loadable(
    lazy(() => import('views/pages/clients/ClientsUser'))
)

const ThrottleSettings = Loadable(
    lazy(() => import('views/pages/throttle-settings'))
)
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <UserSimpleCard />,
        },
        {
            path: '/users',
            element: <UserSimpleCard />,
        },
        {
            path: '/admins',
            element: <Admins />,
        },

        {
            path: '/users/details/:id',
            element: <UserDetail />,
        },
        {
            path: '/user/quiz-details/:id',
            element: <QuizDetails />,
        },
        {
            path: '/clients',
            element: <Clients />,
        },
        {
            path: '/clients/users/:id',
            element: <ClientsUser />,
        },
        {
            path: '/survey-questions',
            element: <Questions />,
        },
        {
            path: '/coaching-survey',
            element: <CoachingQuestions />,
        },
        {
            path: '/quotes',
            element: <Quotes />,
        },
        {
            path: '/mantras',
            element: <Mantras />,
        },
        {
            path: '/welcome',
            element: <AppWelcome />,
        },
        {
            path: '/exit-survey',
            element: <ExitSurvey />,
        },
        {
            path: '/content-categories',
            element: <Preferences />,
        },
        {
            path: '/communication-log',
            element: <CommunicationLog />,
        },
        {
            path: '/communication-log/:id',
            element: <MessageDetails />,
        },
        {
            path: '/throttle-settings',
            element: <ThrottleSettings />,
        },
        {
            path: '/article',
            element: <GenerateArticle />,
        },
    ],
}

export default MainRoutes
